
import axios from 'axios';
export default {
    
    async createExtraHours(params)  {
        return await axios.post(`extra_hours/create` , params)
    },
    async createExtraHoursList(params)  {
        return await axios.post(`extra_hours/create/list` , params)
    },
    async updateExtraHoursColumn(column , value , data)  {
        return await axios.put(`extra_hours/update_list?${column}=${value}` , data)
    },
    async deleteExtraHoursList(list)  {
        return await axios.delete(`extra_hours/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportExtraHours(column , value)  {
        return await axios.get(`extra_hours/report?${column}=${value}`)
    },
    async getAllExtraHours()  {
        return await axios.get(`extra_hours/all`)
    },
    async getOneExtraHours(extra_hour_id)  {
        return await axios.get(`extra_hours/all/${extra_hour_id}`)
    },
    async getExtraHoursByColumn(column , value)  {
        return await axios.get(`extra_hours/filter?column=${column}&value=${value}`)
    },
    async deleteExtraHours(extra_hour_id)  {
        return await axios.delete(`extra_hours/delete/${extra_hour_id}`)
    },
    async updateExtraHours(extra_hour_id , params)  {
        return await axios.put(`extra_hours/update/${extra_hour_id}` , params)
    }
}